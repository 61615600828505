import { useState, useMemo, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import ReactGA from 'react-ga4'
import NavBar from './NavBar'
import ContactFormInline from './ContactFormInline'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'

import owner3 from '../images/happyspati1.png'
import owner3Desktop from '../images/happyspati1-desktop.png'
import messagesAr from '../lang/ar.json'
import messagesDe from '../lang/de.json'
import messagesEn from '../lang/en.json'

function getInitialLocale() {
  if (navigator.language === "de") {
    return "de";
  } else if (navigator.language === "ar") {
    return "ar";
  } else {
    return "en";
  }
}


function SpatiTech() {
  const [locale, setLocale] = useState(getInitialLocale())
  const getLang = useMemo(() => (navigator.languages?.[0] || navigator.language || 'en'), [])
  useEffect(() => {
    setLocale(getLang);
  }, [getLang]);


  const sendEvent = () => {
    ReactGA.event({ // TODO add which random image is used and send it to GA
      category: "test category",
      action: "test action",
      label: "test label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }

  const ownerImage = isDesktop ? owner3Desktop : owner3

  function loadMessages(locale: string) {
    switch (locale) {
      case "ar": // note this is not used yet
        return messagesAr;
      case "en":
        return messagesEn;
      case "de":
        return messagesDe;
      default:
        return messagesEn;
    }
  }


  const rowClassName = 'text-start px-3 mt-3 mb-5'

  return (
    <div className="App">
      <IntlProvider locale={getLang} messages={loadMessages(locale)}>
        <Container className='p-0'>
          <NavBar setLocale={setLocale} locale={locale} />
          <Row><Image src={ownerImage} className="img-fluid" alt="store owner" /></Row>
          <Row className={rowClassName}>
            <Col lg={6} className='my-3 mr-5'>
              <HeadlineSpan onClick={sendEvent}><FormattedMessage id="headline.boostBusiness" /></HeadlineSpan><br />
            </Col>
            <Col lg={6} className='pr-5'>
              <SubH3 className={isDesktop ? 'mt-3' : ''} ><FormattedMessage id="subhead.unlockPower" /></SubH3>
            </Col>
          </Row>
          <Row className='pt-5 mt-3 mb-5 px-5'>
            <Col lg={6}>
              <h3 style={{ fontSize: 20, fontWeight: 700, paddingBottom: '1rem' }}><FormattedMessage id="subhead.interested" /></h3>
            </Col>
            <Col lg={6}>
              <h3 style={{ fontSize: 20 }}><FormattedMessage id="subhead.signUpBelow" /></h3>
            </Col>
          </Row>
          <Row className='text-start px-3 mt-3'>
            <ContactFormInline />
          </Row>
          <Row className='text-start px-3 pt-3 pb-5' style={{ backgroundColor: 'black', color: 'white' }}>
            <Col xs={6}>
              <LargeIcon style={{ opacity: 0.2 }} className="bi-shop"></LargeIcon>
            </Col>
            <Col xs={6} style={{ opacity: 0.4 }}>&copy; 2024 SPATI.tech<br />
              <a href="mailto:info@spati.tech"><FormattedMessage id="contact.link" /></a><br />
            </Col>
          </Row>
        </Container>
      </IntlProvider>
    </div>
  );
}

export default SpatiTech;

export const HeadlineSpan = styled.span`
  font-size: 48px;
  line-height: 2.8rem;
  font-weight: 500;
`
export const SubH3 = styled.h3`
  font-size: 20px;
  opacity: 0.5;
`
export const SubH3Black2 = styled.h3`
  font-size: 20px;
  opacity: 1;
`

export const SubH3Black3 = styled.h3`
  font-size: 20px;
  opacity: 1;
  font-weight: 700;
`

export const SubH3Black4 = styled.h3`
  font-size: 20px;
  opacity: 1;
  font-weight: 700;
`

export const BenefitsH3 = styled.h3`
  color: black;
  font-size: 40px;
`

export const RowWhite = styled(Row)`
  background-color: white;
`


export const RowDark = styled(Row)`
background: black;
color: white;
`
export const RowDark2 = styled(Row)`
  background: black;
  color: white;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;

`

export const RowDark3 = styled(Row)`
  background: red;
  color: blue;
`

export const ImgMax300 = styled.img`
  max-height: 300px;
  margin: auto;
  width: auto;
`

export const SubH3Black = styled.h3`
  max-height: 500px;
  margin: auto;
  width: auto;
  color: black;
  margin-left: 0;
  font-size: 20px;
`

export const LargeIcon = styled.i`
  font-size: 1.5em;
  opacity: 0.5;
`


const Image = styled.img`
  width: 100%;

  @media (min-width: 992px) {
    height: 650px;
    object-fit: cover;
    object-position: center top;
  }
`;